/* ------------------SellRentForMe Styles---------------------- */
.sellRentMain .picture--cover {
    display: block;
    height: 100%;
}

.sellRentMain .tour-header-video {
    text-align: center;
    max-width: 765px;
    display: flex;
    max-height: 400px;
    justify-content: center;
    padding-top: 20px;
}

.sellRentMain .tour-header-video {
    text-align: center;
    max-width: 765px;
    display: flex;
    max-height: 400px;
    justify-content: center;
    padding-top: 20px;
}

.sellRentMain h1.section-heading__heading.gutter-bottom {
    font-size: 24px !important;
    width: 100%;
    font-weight: 300 !important;
    line-height: 40px;
    padding-bottom: 10px;
}


.sellRentMain .mian-field-box .sign-up-label {
    /* background: white !important; */
    height: 14px;
}


.sellRentMain .drawer-link.active {
    color: #9c27b0;
    font-weight: 700;
    text-transform: capitalize !important;
    font-family: open_sans, sans-serif;
}

.sellRentMain .pro-seller-badge {
    left: 110px !important;
}

.sellRentMain .login-stick-button-main {
    display: none;
    visibility: hidden;
    transition: .5s all
}

.sellRentMain .login-stick-button-main.is-sticky {
    display: block;
    visibility: visible;
    position: fixed !important;
    width: 100%;
    background: #ffffffd6;
    -webkit-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    top: 0;
    z-index: 9;
    left: 0;
    text-align: center;
    right: 0
}

.sellRentMain .signup-proseller {
    border: 2px solid #8a288f !important;
    padding: 5px 25px !important;
    background: transparent !important;
    color: #8a288f !important;
    font-size: 14px !important;
    font-weight: 400 !important
}

.sellRentMain .login-proseller {
    background: #8c2291;
    border-color: #8c2291;
    padding: 5px 25px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    border: 2px solid #8a288f !important;
    color: #fff !important
}

.sellRentMain .jo-main-heading {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
}


.sellRentMain .floating-input.floating-select {
    font-weight: 500 !important;
}


.sellRentMain .target-audience-headings picture {
    display: block;
    width: 100%;
}

.sellRentMain .target-audience-headings img.tour-360 {
    max-width: 70%;
    width: 70%;
}

.sellRentMain .variations-video-globe video {
    width: 100%;
}

.sellRentMain button.btn.btn-shadow.cta-send {
    width: 100%;
    padding: 10px 5px !important;
    background: #8c2291;
    color: #fff;
    text-shadow: none;
}

.sellRentMain .btn {
    font-size: 15px !important;
}

.sellRentMain .pro-seller-head-first-head-cta {
    width: 100%;
}

.sellRentMain .space-pb {
    padding: 0 0 30px !important;
}

.sellRentMain .homepage-hero {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;
}

.sellRentMain .grid-three-quarter {
    width: calc(100% - 40%);
    padding-right: 0 !important;
}

.sellRentMain .grid {
    padding-left: 80px;
    padding-right: 80px;
}

.sellRentMain .grid--equal-height, .grid--vertically-centered {
    display: flex;
    flex-wrap: wrap;
}

.sellRentMain .grid--vertically-centered {
    align-items: center;
}

.sellRentMain .section-heading--mobile-up-align-left {
    text-align: left;
}

.sellRentMain .head-cta-input input.inquirynumber.phone.number.floating-input {
    height: 44px !important;
    border: 1px solid #8a288f !important;
}

.sellRentMain .grid-three-quarter {
    width: calc(100% - 40%);
    padding-right: 0 !important;
}

.sellRentMain .grid {
    padding-left: 80px;
    padding-right: 80px;
}

.sellRentMain .grid--equal-height, .sellRentMain .grid--vertically-centered {
    display: flex;
    flex-wrap: wrap;
}

.sellRentMain .grid--vertically-centered {
    align-items: center;
}

.sellRentMain .grid__item {
    display: block;
    position: relative;
    width: 100%;
    float: left;
    padding-left: 4px;
    padding-right: 4px;
}

.sellRentMain .section-heading--mobile-up-align-left {
    text-align: left;
}

.sellRentMain button.btn.btn-shadow.cta-send {
    padding: 10px 5px !important;
    width: 100%;
}

.sellRentMain .btn {
    font-size: 15px !important;
}

.sellRentMain h6.heading-target-audience {
    font-weight: 300 !important;
    font-size: 15px !important;
    letter-spacing: .5px;
}

.sellRentMain #PageContainer {
    overflow: hidden;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.sellRentMain .bg-white-f2 {
    background: #e9e9eb;
}

.sellRentMain .top-header-heading-inner {
    font-size: 32px !important;
    width: 100%;
    font-weight: 700 !important;
    line-height: 48px;
    margin-bottom: 0;
    color: #242021;
}

.sellRentMain .top-header-heading h6 {
    font-size: 24px !important;
    width: 100%;
    font-weight: 300 !important;
    color: #242021;
    text-shadow: 1px 0 0 #1b1b1b82;
}

.sellRentMain .heading-target-audience {
    font-size: 18px !important;
    line-height: 24px;
    font-family: open_sans, sans-serif;
    color: #333;
    margin-top: 0;
    font-weight: 300;
    text-transform: capitalize;
    text-shadow: 1px 0 0 #1b1b1b82;
    margin-bottom: 0.5rem;
}

.sellRentMain .disclaimer-content {
    background-color: #f7f7f8;
}

.sellRentMain .btn.btn-disclaimer {
    background: #5bab46 !important;
    border-color: #5bab46 !important;
    color: #fff !important;
    font-size: 11px !important;
    border: 0 solid #5bab46 !important;
    padding: 3px 15px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
}

.sellRentMain .videography-main {
    border-radius: 20px;
    margin-top: 5px;
    box-shadow: 0 3px 12px 0 rgb(109 109 109 / 59%);
}