.joProject .navbar .navbar-nav .nav-link {
    font-weight: 500;
    font-size: 15px;
    padding: 10px 20px;
    color: #fff;
    text-transform: capitalize;
    cursor: pointer;
    text-shadow: 1px 0 0 #1b1b1b82;
}

.joProject .bg-dark {
    background: #000!important;
}

.BrowserView .banner-header-project {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 768px;
    height: 100%;
}

.BrowserView .jo-features-details-box {
    border: 1px solid #eee;
    padding: 15px;
    box-shadow: 0 3px 12px 0 rgb(109 109 109 / 15%);
    width: 100%;
    position: relative;
    border-radius: 0 0 0px 20px;
    padding-top: 35px;
    padding-bottom: 20px;
    background: white;
    height: 150px;
}

.BrowserView .jo-features-box-icon {
    background: #e37628;
    padding: 10px;
    border-radius: 0 0 0px 10px;
    position: absolute;
    top: -20px;
    box-shadow: -4px 4px 8px 2px rgb(109 109 109 / 15%);
}

.BrowserView .jo-features-us-social {
    width: 24px;
    height: 24px !important;
}

.BrowserView .jo-features-box-detail-inner h6 {
    color: #e37628;
    text-shadow: none;
}

.BrowserView .jp-features-section {
    background-image: url(../../../../public/classified_assets/images/jo-projects/king-comfort-v2/dot_wall.jpg);
    background-repeat: no-repeat;
}

.BrowserView .jp-features-section h1 {
    font-size: 40px !important;
}

.BrowserView .about-builder-section {
    background-image: url(../../../../public/classified_assets/images/jo-projects/king-comfort-v2/dark_banner.jpg);
    background-repeat: no-repeat;
}

.BrowserView .about-developer-img {
    border-radius: 0 0 0px 31px;
}

.BrowserView .about-developer-descp h1 {
    font-size: 36px !important;
    color: #fff;
    font-weight: 100;
}

.BrowserView .about-developer-descp span {
    font-weight: 500;
}

.BrowserView .jo-location-points {
    border: 1px solid #e37628;
    height: 64px;
    padding: 10px;
    border-left-width: 5px !important;
    margin-bottom: 10px;
}

.BrowserView .jo-location-points-route-km {
    font-weight: 600;
    color: #000;
}

.BrowserView .jo-location-points-route {
    color: #ea393f;
    font-weight: 700 !important;
}

.BrowserView .jo-proj-loc-main {
    background-image: url(../../../../public/classified_assets/images/jo-projects/king-comfort-v2/sketchbuilding.jpg);
    background-repeat: no-repeat;
}

.BrowserView .inns-plans-section {
    background-image: url(../../../../public/classified_assets/images/jo-projects/king-comfort-v2/customshape.jpg);
    background-repeat: no-repeat;
}

.BrowserView .starting-from-inner {
    border: 5px dotted #e37628;
    text-align: center;
    padding: 25px 10px;
    border-radius: 7px;
}

.BrowserView .black-head {
    font-size: 16.5px;
    font-weight: 600;
    color: #333;
}

.BrowserView .read-head {
    font-size: 28px;
    color: #ed393f;
    font-weight: 600;
}

.BrowserView .read-head span {
    font-size: 16px;
}

.BrowserView .starting-from-main-inner-head {
    font-size: 38px;
    color: #333;
    margin: 15px 0;
}

.BrowserView .read-head-blk {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    margin: 25px 0 5px 0;
}

.BrowserView .read-head-blk span {
    color: #ed393f;
}

.BrowserView .pj-is-sticky {
    position: fixed !important;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    top: 0;
    z-index: 9;
}

.BrowserView .jo-location-heading {
    text-align: center;
    font-size: 38px;
    color: #333;
    width: 100%;
}


/*Mobile CSS*/
.MobileView .banner-header-project {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 240px;
}

.MobileView .jo-features-details-box {
    border: 1px solid #eee;
    box-shadow: 0 3px 12px 0 rgb(109 109 109 / 15%);
    width: 100%;
    position: relative;
    background: white;
    display: flex;
}

.MobileView .jo-features-box-icon {
    background: #e37628;
    padding: 10px;
    /*border-radius: 0 0 0px 10px;*/
    /*position: absolute;*/
    /*top: -20px;*/
    box-shadow: -4px 4px 8px 2px rgb(109 109 109 / 15%);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 64px;
}

.MobileView .jo-features-us-social {
    width: 36px;
    height: 36px !important;
}

.MobileView .jo-features-box-detail-inner h6 {
    color: #e37628;
    text-shadow: none;
    margin: 0;
    font-size: 16px !important;
    margin-top: 5px;
    font-weight: 600;
}

.MobileView .jo-features-box-detail-inner p {
    margin-bottom: 5px;
}

.MobileView .jo-features-box-detail-inner {
    padding: 0 5px;
}

.MobileView .jp-features-section {
    background-image: url(../../../../public/classified_assets/images/jo-projects/king-comfort-v2/dot_wall.jpg);
    background-repeat: no-repeat;
}

.MobileView .jp-features-section h1 {
    font-size: 28px !important;
}

.MobileView .about-builder-section {
    background-image: url(../../../../public/classified_assets/images/jo-projects/king-comfort-v2/dark_banner.jpg);
    background-repeat: no-repeat;
}

.MobileView .about-developer-img {
    border-radius: 0 0 0px 31px;
}

.MobileView .about-developer-descp h1 {
    font-size: 16px !important;
    color: #fff;
    font-weight: 100;
}

.MobileView .about-developer-descp span {
    font-weight: 500;
    color: #ea393f;
}

.MobileView .jo-location-points {
    border: 1px solid #e37628;
    height: 64px;
    padding: 10px;
    border-left-width: 5px !important;
    margin-bottom: 10px;
}

.MobileView .jo-location-points-route-km {
    font-weight: 600;
    color: #000;
}

.MobileView .jo-location-points-route {
    color: #ea393f;
    font-weight: 700 !important;
}

.MobileView .jo-proj-loc-main {
    background-image: url(../../../../public/classified_assets/images/jo-projects/king-comfort-v2/sketchbuilding.jpg);
    background-repeat: no-repeat;
}

.MobileView .inns-plans-section {
    background-image: url(../../../../public/classified_assets/images/jo-projects/king-comfort-v2/customshape.jpg);
    background-repeat: no-repeat;
}

.MobileView .starting-from-inner {
    border: 5px dotted #e37628;
    text-align: center;
    padding: 25px 10px;
    border-radius: 7px;
}

.MobileView .black-head {
    font-size: 16.5px;
    font-weight: 600;
    color: #333;
}

.MobileView .read-head {
    font-size: 28px;
    color: #ed393f;
    font-weight: 600;
}

.MobileView .read-head span {
    font-size: 16px;
}

.MobileView .starting-from-main-inner-head {
    font-size: 28px;
    color: #333;
    margin: 15px 0;
}

.MobileView .read-head-blk {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 25px 0 5px 0;
}

.MobileView .read-head-blk span {
    color: #ed393f;
}

.MobileView .starting-from-main {
    text-align: center;
}

footer.footer.space-pt.footer-mobile {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

/*.project-nav-jo{*/
/*    display: none;*/
/*    visibility: hidden;*/
/*}*/

.project-nav-jo.pj-is-sticky {
    position: fixed !important;
    width: 100%;
    -webkit-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    top: 0;
    z-index: 9;
    display: block;
    visibility: visible;
}

.joProject .navbar-brand img {
    width: 130px;
}

.project-nav-jo {
    background: black;
}

.sticky-nav-box{
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 7px;
}

.project-nav-jo ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.sticky-nav-item .nav-link {
    padding: 8px;
}

.sticky-nav-box img {
    width: 20px;
    height: 20px;
}
.sticky-nav-name {
    font-size: 12px;
    color: #fff;
    margin-top: 5px;
    font-weight: 600;
}

.MobileView .jo-location-iframe iframe {
    width: 100%;
}

.MobileView .jo-location-heading {
    text-align: center;
    font-size: 28px;
    color: #333;
    width: 100%;
}

.MobileView span.line-horizontal {
    font-weight: 300 !important;
}

.MobileView .jo-location-heading span {
    font-weight: 500;
}