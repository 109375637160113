/* ------------------Jaga360 Styles---------------------- */
.tourJoMain .picture--cover {
    display: block;
    height: 100%;
}
.tourJoMain .tour-header-video {
    text-align: center;
    max-width: 765px;
    display: flex;
    max-height: 400px;
    justify-content: center;
    padding-top: 20px;
}

.tourJoMain .gutter-bottom {
    font-size: 24px!important;
    width: 100%;
    font-weight: 300!important;
    line-height: 40px;
    padding-bottom: 10px;
}

.tourJoMain sign-up-label {
    /* background: white !important; */
    height: 14px;
}


.tourJoMain .drawer-link.active {
    color: #9c27b0;
    font-weight: 700;
    text-transform: capitalize !important;
    font-family: open_sans, sans-serif;
}

.tourJoMain .pro-seller-badge {
    left: 110px !important;
}


.tourJoMain .floating-input {
    height: 44px!important;
    border: 1px solid #8a288f!important;

}

.tourJoMain .login-stick-button-main {
    display: none;
    visibility: hidden;
    transition: .5s all
}

.tourJoMain .is-sticky {
    display: block;
    visibility: visible;
    position: fixed !important;
    width: 100%;
    background: #ffffffd6;
    -webkit-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    top: 0;
    z-index: 9;
    left: 0;
    text-align: center;
    right: 0
}

.tourJoMain .signup-proseller {
    border: 2px solid #8a288f !important;
    padding: 5px 25px !important;
    background: transparent !important;
    color: #8a288f !important;
    font-size: 14px !important;
    font-weight: 400 !important
}

.tourJoMain .login-proseller {
    background: #8c2291;
    border-color: #8c2291;
    padding: 5px 25px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    border: 2px solid #8a288f !important;
    color: #fff !important
}
.tourJoMain .jo-main-heading {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
}


.tourJoMain .floating-input.floating-select{
    font-weight: 500!important;
}


.tourJoMain .target-audience-headings picture {
    display: block;
    width: 100%;
}
.tourJoMain .tour-360 {
    max-width: 70%;
    width: 70%;
}

.tourJoMain .variations-video-globe video {
    width: 100%;
}
.tourJoMain .cta-send {
    width: 100%;
    padding: 10px 5px !important;
    background: #8c2291;
    color: #fff;
    text-shadow: none;
}

.tourJoMain .pro-seller-head-first-head-cta {
    width: 100%;
}
.tourJoMain .space-pb {
    padding: 0 0 30px!important;
}
.tourJoMain .homepage-hero {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;
}
.tourJoMain .grid-three-quarter {
    width: calc(100% - 40%);
    padding-right: 0!important;
}
.tourJoMain .grid {
    padding-left: 80px;
    padding-right: 80px;
}
.tourJoMain .grid--equal-height, .grid--vertically-centered {
    display: flex;
    flex-wrap: wrap;
}
.tourJoMain .grid--vertically-centered {
    align-items: center;
}
.tourJoMain .section-heading--mobile-up-align-left {
    text-align: left;
}

.tourJoMain .grid-three-quarter {
    width: calc(100% - 40%);
    padding-right: 0!important;
}
.tourJoMain .grid {
    padding-left: 80px;
    padding-right: 80px;
}
.tourJoMain .grid--equal-height, .grid--vertically-centered {
    display: flex;
    flex-wrap: wrap;
}
.tourJoMain .grid--vertically-centered {
    align-items: center;
}
.tourJoMain .grid__item {
    display: block;
    position: relative;
    width: 100%;
    float: left;
    padding-left: 4px;
    padding-right: 4px;
}
.tourJoMain .section-heading--mobile-up-align-left {
    text-align: left;
}
.tourJoMain .cta-send {
    padding: 10px 5px!important;
    width: 100%;
}
.tourJoMain .font-weight .btn {
    font-size: 15px!important;
}
.tourJoMain .btn-shadow {
    background: var(--theme-color)!important;
    color: #fff!important;
    padding: 10px 40px!important;
    box-shadow: 0 15px 8px -14px var(--theme-color);
    line-height: 22px;
}

.tourJoMain h6.heading-target-audience {
    font-weight: 300!important;
    font-size: 15px!important;
    letter-spacing: .5px;
}
.tourJoMain #PageContainer {
    overflow: hidden;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.tourJoMain .tourJoMain .bg-white-f2 {
    background: #e9e9eb;
}
.tourJoMain .top-header-heading-inner {
    font-size: 32px!important;
    width: 100%;
    font-weight: 700!important;
    line-height: 48px;
    margin-bottom: 0;
    color: #242021;
}
.tourJoMain .top-header-heading h6 {
    font-size: 24px!important;
    width: 100%;
    font-weight: 300!important;
    color: #242021;
    text-shadow: 1px 0 0 #1b1b1b82;
}
.tourJoMain .heading-target-audience {
    font-size: 18px!important;
    line-height: 24px;
    font-family: open_sans,sans-serif;
    color: #333;
    margin-top: 0;
    font-weight: 300;
    text-transform: capitalize;
    text-shadow: 1px 0 0 #1b1b1b82;
    margin-bottom: 0.5rem;
}